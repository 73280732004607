import React from "react"
import Layout from "../components/common/Layout"
// import FormCon from "../components/Form/FormCon"
// import FieldArray from "../components/Form/FieldArray"
// import LoadBtn from "../components/Form/LoadBtn"
import styles from "../css/contact.module.css"

const campForm = {
    inputs: {
        name: {
            name: "name",
            type: "text",
            placeholder: "Enter your name",
            className: "",
        },
        email: {
            name: "email",
            type: "email",
            placeholder: "Enter your email",
            className: "",
        },
        message: {
            name: "message",
            type: "textarea",
            placeholder: "Enter your message",
            className: "",
        },
    },
    allIds: ["name", "email", "message"],
}

const Contact = () => {
    return (
        <Layout>
            <section className={`${styles.section} bg-blue-black`}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h1 className={styles.heading}>Contact us</h1>
                            <p className={styles.desc}>
                                Share the details of your project – like scope,
                                timeframes, or business challenges you'd like to
                                solve. Our team will carefully study them and
                                then we’ll figure out the next move together.
                            </p>
                            <div className="row pb-5">
                                <div className="col-6">
                                    <div className={styles.subheading}>Project inquiries</div>
                                    <div className={styles.subdesc}>official@billiontags.com</div>
                                </div>
                                <div className="col-6">
                                    <div className={styles.subheading}>Careers</div>
                                    <div className={styles.subdesc}>careers@billiontags.com</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className={styles.form}>
                                <div className={styles.formHeading}>
                                    Got a project in mind?
                                </div>
                                <div className="form-group">
                                    <input placeholder="Full Name" className={styles.ip} />
                                </div>
                                <div className="form-group">
                                    <input placeholder="Email Address" className={styles.ip} />
                                </div>
                                <div className="form-group">
                                    <input placeholder="Company Name" className={styles.ip} />
                                </div>
                                <div className="form-group">
                                    <input placeholder="Phone Number" className={styles.ip} />
                                </div>
                                <div className="form-group">
                                    <input placeholder="About Project" className={styles.ip} />
                                </div>
                                <div className="form-group">
                                    <button className="btn btn-block btn-dark btn-lg">
                                        SUBMIT
                                    </button>   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Contact
